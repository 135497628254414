import React from "react";
import styled from "styled-components";
import { HeaderUnderline } from "../HeaderUnderline/HeaderUnderline";
import { Section } from "../Section/Section";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { ContactForm } from "../ContactForm/ContactForm";

export interface ContactFormSectionProps {
  className?: string;
}

const ContactFormSectionInner = (props: ContactFormSectionProps) => {
  const { t } = useTranslation("common");
  return (
    <Section className={props.className}>
      <Container>
        <HeaderUnderline>{t("contactForm.title")}</HeaderUnderline>
        <ContactForm />
      </Container>
    </Section>
  );
};

export const ContactFormSection = styled(ContactFormSectionInner)``;
