import React, { useCallback, useMemo } from "react";
import { BiPhoneCall } from "react-icons/bi";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { muiTheme } from "../../../../lib/Theme";
import { useFormikContext } from "formik";
import { CircularProgress } from "@material-ui/core";

export interface ContactFormFooterProps {
  className?: string;
  submitting?: boolean
}

const Progress = () => (
  <CircularProgress
    style={{
      height: 12,
      width: 12,
      color: "white",
      marginLeft: 10
    }}
  />
);

const ContactFormFooterInner = (props: ContactFormFooterProps) => {
  const { t } = useTranslation("common");
  const { submitForm, isSubmitting } = useFormikContext();
  const submitText = useMemo(() => t("contactForm.submit"), []);
  return (
    <div className={props.className}>
      <div className="callus">
        <div className="callus__icon">
          <BiPhoneCall />
        </div>
        <h2 className="callus__text">
          {t("contactInformation.callUs")}:{" "}
          <a href={`tel:${t("contactInformation.phoneNumber")}`}>
            {t("contactInformation.phoneNumber")}
          </a>
        </h2>
      </div>
      <div className="submit-button-wrapper">
        <button type="submit" className="submit-button">
          {submitText}
          {props.submitting && <Progress />}
        </button>
      </div>
    </div>
  );
};

export const ContactFormFooter = styled(ContactFormFooterInner)`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .callus {
    display: flex;
    align-items: center;

    &__icon {
      font-size: 40px !important;
      color: ${props => props.theme.color.primary};
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
    &__text {
      font-size: 2.8rem;
      font-weight: 700;
      margin: 0;
    }
  }

  .submit-button {
    padding: 15px 25px;
    background: ${props => props.theme.color.primary};
    color: white;
    text-transform: uppercase;
    border-radius: 3px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    border: none;
  }
  flex-wrap: wrap;
  ${muiTheme.breakpoints.down("sm")} {
    .callus {
      width: 100%;
      flex: 1 1 auto;
      margin-bottom: 20px;
    }
    .submit-button-wrapper {
      width: 100%;
      flex: 1 1 auto;
    }
  }
`;
