import React, { useMemo } from "react";
import styled from "styled-components";
import { FastField } from "formik";
import { useTranslation } from "react-i18next";
import { rgba } from "polished";

export interface ContactFormSubjectSelectProps {
  className?: string;
}

const CustomSelect = styled.select`
  display: flex;
  width: 100%;
  padding: 1.5rem;
  border-radius: 3px;
  border: 2px solid ${props => props.theme.color.border};
  font-size: 1.6rem;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  background: white;
  ::placeholder {
    color: ${props => rgba(props.theme.color.primaryDark, 0.5)};
    opacity: 1; /* Firefox */
  }
`;

const ContactFormSubjectSelectInner = (
  props: ContactFormSubjectSelectProps
) => {
  const { t } = useTranslation("common");
  const options = useMemo(
    () => t("contactForm.howCanWeHelpYou.options", { returnObjects: true }),
    []
  );
  return (
    <>
      <FastField name="type">
        {({ field }) => (
          <CustomSelect {...field}>
            {options.map(option => (
              <option value={option}>{option}</option>
            ))}
          </CustomSelect>
        )}
      </FastField>
    </>
  );
};

export const ContactFormSubjectSelect = styled(ContactFormSubjectSelectInner)``;
