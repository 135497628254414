import { useCallback, useState } from "react";

export enum FormState {
  idle = "idle",
  submitting = "submitting",
  submitted = "submitted"
}

const encode = (data: any) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

interface Params {
  onNotAgreed: () => void;
}

export const useSubmitFormHook = ({ onNotAgreed }: Params) => {
  const [formState, setFormState] = useState<FormState>(FormState.idle);

  const formSubmit = useCallback(values => {
    console.log(values);

    if (values.agreement !== true) {
      setFormState(FormState.idle);
      onNotAgreed();
      return;
    }
    return new Promise(resolve => {
      setFormState(FormState.submitting);
      const formData = new FormData();
      for (const key in values) {
        formData.append(key, values[key]);
      }

      fetch("/kontakt/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...values })
      }).then(() => {
        setTimeout(() => {
          setFormState(FormState.submitted);
          resolve();
        }, 1000);
      });
    });
  }, []);

  return {
    formState,
    formSubmit
  };
};
