import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ContactFormForm } from "./ContactFormForm/ContactFormForm";
import { FormState, useSubmitFormHook } from "./hooks/useSubmitFormHook";

export type ContactFormProps = React.HTMLAttributes<HTMLElement>;

const initialValues = {
    name: "",
    email: "",
    address: "",
    phone: "",
    number: "",
    message: "",
    type: "",
    agreement: false,
};

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ContactFormInner: React.FC<ContactFormProps> = (
    props: ContactFormProps
) => {
    const [open, setOpen] = React.useState(false);
    const [notAgreed, setNotAgreed] = useState(false);

    const { formState, formSubmit } = useSubmitFormHook({
        onNotAgreed: () => {},
    });

    useEffect(() => {
        if (formState === FormState.submitted) {
            setOpen(true);
        }
    }, [formState]);

    return (
        <div className={props.className}>
            {/* HIDDEN  netlify form */}
            <form
                name="contact"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                hidden
            >
                <input type="hidden" name="form-name" value="contact-form" />
                <input type="hidden" name="bot-field" />
                <input type="text" name="name" />
                <input type="email" name="email" />
                <input type="text" name="address" />
                <input type="text" name="phone" />
                <input type="text" name="number" />
                <textarea name="message" />
                <input type="text" name="type" />
                <input type="checkbox" name="agreement" />
            </form>
            <Formik onSubmit={formSubmit} initialValues={initialValues}>
                <ContactFormForm
                    isSubmitting={formState === FormState.submitting}
                    formState={formState}
                />
            </Formik>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={() => setOpen(false)}
            >
                <Alert
                    style={{
                        fontSize: 16,
                    }}
                    severity="success"
                >
                    Email bol úspešne odoslaný
                </Alert>
            </Snackbar>
            <Snackbar
                open={notAgreed}
                autoHideDuration={3000}
                onClose={() => setNotAgreed(false)}
            >
                <Alert
                    style={{
                        fontSize: 16,
                    }}
                    severity="error"
                >
                    Musíte potvrdiť, že súhlasíte.
                </Alert>
            </Snackbar>
        </div>
    );
};

export const ContactForm = styled(ContactFormInner)<ContactFormProps>`
    padding: 3rem;
    border-radius: 3px;
    border: 2px solid ${(props) => props.theme.color.border};
    display: flex;
    flex-flow: column;
    max-width: 100%;
    overflow: hidden;
    flex: 1 1 auto;
`;
