import React from "react";
import { FastField } from "formik";
import { rgba } from "polished";
import styled from "styled-components";

export interface ContactFormItemProps {
    className?: string;
    name: string;
    label: string;
    placeholder: string;
    textarea?: boolean;
    required?: boolean;
    type?: string;
}

const ContactFormItemInner = (props: ContactFormItemProps) => {
    const {
        name,
        label,
        placeholder,
        textarea,
        required,
        type = "text",
    } = props;
    return (
        <div className={props.className}>
            <FastField name={name} required={required}>
                {({ field }) => (
                    <>
                        <label
                            className={"visuallyhidden"}
                            htmlFor={`contact-form__${name}`}
                        >
                            {label}
                        </label>
                        {textarea ? (
                            <textarea
                                {...field}
                                id={`contact-form__${name}`}
                                placeholder={placeholder}
                                rows={4}
                                required={required}
                            />
                        ) : (
                            <input
                                {...field}
                                type={type}
                                id={`contact-form__${name}`}
                                placeholder={placeholder}
                                required={required}
                            />
                        )}
                    </>
                )}
            </FastField>
        </div>
    );
};

export const ContactFormItem = styled(ContactFormItemInner)`
    display: flex;
    flex: 1 1 auto;

    input,
    textarea {
        display: flex;
        flex: 1;
        padding: 1.5rem;
        border-radius: 3px;
        border: 2px solid ${(props) => props.theme.color.border};
        font-size: 1.6rem;
        font-family: "Roboto", Arial, Helvetica, sans-serif;
        ::placeholder {
            color: ${(props) => rgba(props.theme.color.primaryDark, 0.5)};
            opacity: 1; /* Firefox */
        }
    }
`;
