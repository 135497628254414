import styled from "styled-components";

export const Section = styled.section<{
  background?: boolean;
}>`
  margin-top: ${props => props.theme.spacing.sectionMarginTop};
  ${({ background, theme }) =>
    background &&
    `
    padding: 30px 0;
    background: ${theme.color.sectionBg}
  `}
`;
