import {
    Checkbox,
    FormControlLabel,
    Grid,
    withStyles,
} from "@material-ui/core";
import { Form, useField, useFormikContext } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components";
import { theme } from "../../../../lib/Theme";
import { ContactFormFooter } from "../ContactFormFooter/ContactFormFooter";
import { ContactFormItem } from "../ContactFormItem/ContactFormItem";
import { ContactFormSubjectSelect } from "../ContactFormSubjectSelect/ContactFormSubjectSelect";
import { FormState } from "../hooks/useSubmitFormHook";

export interface ContactFormFormProps {
    isSubmitting?: boolean;
    formState: FormState;
    className?: string;
}

const GreenCheckbox = withStyles({
    root: {
        color: theme.color.border,
        "&$checked": {
            color: theme.color.primary,
        },
    },
    checked: {},
})((props) => {
    const [field] = useField("agreement");

    return (
        <Checkbox
            labelStyle={{
                color: "red",
            }}
            color="default"
            {...field}
            {...props}
            checked={field.value}
        />
    );
});

const ContactFormFormInner = (props: ContactFormFormProps) => {
    const { t } = useTranslation("common");
    const { resetForm } = useFormikContext();
    const { formState } = props;

    React.useEffect(() => {
        if (formState === FormState.submitted) {
            resetForm();
        }
    }, [formState]);

    return (
        <Form className={props.className}>
            <Grid container spacing={3}>
                <Grid item container xs={12} sm={6}>
                    <ContactFormItem
                        required
                        name="name"
                        placeholder={t("contactForm.name")}
                        label={t("contactForm.name")}
                    />
                </Grid>
                <Grid item container xs={12} sm={6}>
                    <ContactFormSubjectSelect />
                </Grid>
                <Grid item container xs={12} sm={6}>
                    <ContactFormItem
                        name="email"
                        type="email"
                        required
                        placeholder={t("contactForm.email")}
                        label={t("contactForm.email")}
                    />
                </Grid>
                <Grid item container xs={12} sm={6}>
                    <ContactFormItem
                        name="address"
                        placeholder={t("contactForm.address")}
                        label={t("contactForm.address")}
                    />
                </Grid>
                <Grid item container xs={12} sm={6}>
                    <ContactFormItem
                        name="phone"
                        placeholder={t("contactForm.phone")}
                        label={t("contactForm.phone")}
                    />
                </Grid>
                <Grid item container xs={12} sm={6}>
                    <ContactFormItem
                        name="number"
                        placeholder={t("contactForm.number")}
                        label={t("contactForm.number")}
                    />
                </Grid>
                <Grid item container xs={12}>
                    <ContactFormItem
                        textarea
                        name="message"
                        placeholder={t("contactForm.message")}
                        label={t("contactForm.message")}
                        required
                    />
                </Grid>
            </Grid>
            <FormControlLabel
                name="agreement"
                style={{
                    color: theme.color.gray,
                }}
                control={<GreenCheckbox required name="agreement" />}
                label="Udeľujem súhlas na spracovanie osobných údajov pre informačné a marketingové účely."
            />
            <ContactFormFooter submitting={props.isSubmitting} />
        </Form>
    );
};

export const ContactFormForm = styled(ContactFormFormInner)`
    input,
    textarea {
        display: flex;
        flex: 1;
        padding: 1.5rem;
        border-radius: 3px;
        border: 2px solid ${(props) => props.theme.color.border};
        font-size: 1.6rem;
        font-family: "Open Sans", Arial, Helvetica, sans-serif;
        outline-color: ${(props) => props.theme.color.primary};
        ::placeholder {
            color: ${(props) => rgba(props.theme.color.primaryDark, 0.5)};
            opacity: 1; /* Firefox */
        }
    }

    * {
        outline-color: ${(props) => props.theme.color.primary};
    }

    input[type="checkbox"] {
        transform: scale(1.4);
        padding: 10px;
    }

    .agreement {
        display: flex;
        margin-left: 4px;

        span {
            color: #b0b0b0;
            margin-left: 1rem;
            font-size: 1.2rem;
        }
    }
    .MuiSvgIcon-root {
        font-size: 2.5rem;
    }

    .MuiFormControlLabel-label {
        font-size: 1.2rem;
    }
`;
